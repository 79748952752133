import React, { useState, useEffect } from "react";
import Select from "react-select";

import config from "../config";
import { getJwtToken } from '../auth';

import checkUserStatus from '../checkuserstatus'

import data_matches from "./wedstrijden.json";
import data_all_players from "./all_players.json"
import data_dutch_players from "./dutch_players.json";
import data_all_teams from "./all_teams.json";


const UitslagenPage: React.FC = () => {
  const reactServer = config.reactServer;
  const [notification, setNotification] = useState("");

  const [results, setResults] = useState({
    // Initialize with empty values for all matches and bonusvragen
    bonusvraag1: "",
    bonusvraag2: "",
    bonusvraag3: "",
    bonusvraag4: "",
    bonusvraag5: "",
    bonusvraag6: "",
    bonusvraag7: "",
    bonusvraag8: "",
    bonusvraag9: "",
    bonusvraag10: "",
  });

  const dutchPlayerOptions = data_dutch_players.map((player) => ({
    label: player.name,
    value: player.name,
  }));

  const allPlayerOptions = data_all_players.map((player) => ({
    label: player.name,
    value: player.name,
  }));

  const countryOptions = data_all_teams.map((country) => ({
    label: country,
    value: country,
  }));

  const handleUpdateResults = async () => {
    try {
      const token = getJwtToken();

      console.log(results)

      const response = await fetch(`${reactServer}/update_results`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(results),
      });

      if (response.ok) {
        console.log("Results updated successfully");
        setNotification("Resultaten geüpdate!");
      } else {
        console.error("Failed to update results");
        setNotification("Fout in resultaten uploaden");
        // Handle failure, show error message, etc.
      }
    } catch (error) {
      console.error("Error updating results:", error);
      setNotification("Error met de resultaten" + error.message);
      // Handle network errors, server down, etc.
    }
  };


  const [tournamentWinner, setTournamentWinner] = useState('');


  const handleWinnerChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;

    try {
      const token = getJwtToken();
      const response = await fetch(`${reactServer}/update_winner_uitslag`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
          winner: value,
        }),
      });

      if (response.ok) {
        console.log('Tournament winner updated successfully!');
        setTournamentWinner(value);
      } else {
        console.error('Failed to update tournament winner');
      }
    } catch (error) {
      console.error('Error updating tournament winner:', error);
    }
  };

    // login handling
    const [userStatus, setUserStatus] = useState({
      logged_in: false,
      is_admin: false,
      betaald: false,
      naam: '',
      email: ''
    });
  
    useEffect(() => {
      const token = getJwtToken();
  
      const fetchData = async () => {
        const status = await checkUserStatus();
        setUserStatus(status);
        console.log('hierrrr')
        console.log(status)
      };
      fetchData();
    }, []);


  return (
    <div className="centered">

      {userStatus.is_admin &&
        <div>

        <h1>Uitslagen</h1>
        <table>
          <thead>
            <tr>
              <th>Match</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {data_matches.map((match, index) => (
              <tr key={index}>
                <td>{`match nr: ${match.MatchNumber}`}</td>
                <td>
                  {match.MatchNumber > 36 ? ( // Render a different select for matches with number > 36
                    <select
                      name={`match-${match.MatchNumber}`}
                      value={results['match' + match.MatchNumber]}
                      onChange={(e) =>
                        setResults({
                          ...results,
                          ['match' + match.MatchNumber]: e.target.value,
                        })
                      }
                    >
                      <option value="niet bekend">Select Result</option>
                      <option value="reset">Reset</option>                    
                      {countryOptions.map((country, index) => (
                        <option key={`winner_${index}`} value={country.value}>{country.label}</option>
                      ))}
                    </select>
                  ) : (
                    <select
                      name={`match-${match.MatchNumber}`}
                      value={results['match' + match.MatchNumber]}
                      onChange={(e) =>
                        setResults({
                          ...results,
                          ['match' + match.MatchNumber]: e.target.value,
                        })
                      }
                    >
                      <option value="niet bekend">Select Result</option>
                      <option value="reset">Reset</option>
                      <option value={match.HomeTeam}>Win: {match.HomeTeam}</option>
                      <option value="Gelijkspel">Gelijkspel</option>
                      <option value={match.AwayTeam}>Win: {match.AwayTeam}</option>
                    </select>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className='winnaar'>
          <h1>Winnaar van het toernooi</h1>
          <select
            className="big-dropdown" // Apply the class here
            onChange={(e) => handleWinnerChange(e)}
            // value={tournamentWinner || ''}
          >
            <option value="">Selecteer winnaar</option>
            <option value="Nog niet bekend">Reset</option>
            {countryOptions.map((country, index) => (
              <option key={`winner_${index}`} value={country.value}>{country.label}</option>
            ))}
          </select>
        </div>

        <h1>Bonusvragen</h1>
        <tbody>
          {Array.from({ length: 10 }).map((_, index) => (
            <tr key={index}>
              <td>{`Bonusvraag ${index + 1}`}</td>
              <td>
                {index === 0 ? (
                  <Select
                    name={`bonusvraag${index + 1}`}
                    value={
                      results[`bonusvraag${index + 1}`]
                        ? allPlayerOptions.find(
                          (option) =>
                            option.value === results[`bonusvraag${index + 1}`]
                        )
                        : null
                    }
                    onChange={(selectedOption) =>
                      setResults({
                        ...results,
                        [`bonusvraag${index + 1}`]: selectedOption
                          ? selectedOption.value
                          : "",
                      })
                    }
                    options={allPlayerOptions}
                    placeholder="Select player"
                    noOptionsMessage={() => "No players found"}
                    isClearable={true}
                    isSearchable={true}
                  />
                ) : index === 1 ? (
                  <Select
                    name={`bonusvraag${index + 1}`}
                    value={
                      results[`bonusvraag${index + 1}`]
                        ? dutchPlayerOptions.find(
                          (option) =>
                            option.value === results[`bonusvraag${index + 1}`]
                        )
                        : null
                    }
                    onChange={(selectedOption) =>
                      setResults({
                        ...results,
                        [`bonusvraag${index + 1}`]: selectedOption
                          ? selectedOption.value
                          : "",
                      })
                    }
                    options={dutchPlayerOptions}
                    placeholder="Select player"
                    noOptionsMessage={() => "No players found"}
                    isClearable={true}
                    isSearchable={true}
                  />
                ) : index > 4 ? (
                  <input
                    type="number"
                    name={`bonusvraag${index + 1}`}
                    value={results[`bonusvraag${index + 1}`]}
                    onChange={(e) =>
                      setResults({
                        ...results,
                        [`bonusvraag${index + 1}`]: e.target.value,
                      })
                    }
                  />
                ) : (
                  <select
                    name={`bonusvraag${index + 1}`}
                    value={results[`bonusvraag${index + 1}`]}
                    onChange={(e) =>
                      setResults({
                        ...results,
                        [`bonusvraag${index + 1}`]: e.target.value,
                      })
                    }
                  >
                    <option value="">Select country</option>
                    {(data_all_teams as string[]).map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <button onClick={handleUpdateResults}>Update Results</button>
        {notification}
      </div>}
    </div>
  );
};

export default UitslagenPage;
