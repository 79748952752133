import React, { useState, useEffect } from 'react';
import config from '../config';
import './voorspellingen.css'; // Import CSS file
import mapping from "./mapping.json";
import flagMapping from './flags.json';

import { getJwtToken, logout } from '../auth';
import checkUserStatus from '../checkuserstatus';

const VoorspellingenPage: React.FC = () => {
  const reactServer = config.reactServer;
  const [predictions, setPredictions] = useState([]);
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);

  // login handling
  const [userStatus, setUserStatus] = useState({
    logged_in: false,
    is_admin: false,
    betaald: false,
    naam: '',
    email: ''
  });

  useEffect(() => {
    const token = getJwtToken();

    const fetchData = async () => {
      const status = await checkUserStatus();
      setUserStatus(status);
      console.log('hierrrr')
      console.log(status)
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const response = await fetch(reactServer + 'get_all_user_predictions');
        if (response.ok) {
          const data = await response.json();
          setPredictions(data);
          console.log(data);
        } else {
          console.error('Failed to fetch predictions');
        }
      } catch (error) {
        console.error('Error fetching predictions:', error);
      }
    };

    const fetchLastUpdated = async () => {
      try {
        const response = await fetch(reactServer + 'get_last_updated');
        if (response.ok) {
          const data = await response.json();
          setLastUpdated(data.last_updated);
        } else {
          console.error('Failed to fetch last updated datetime');
        }
      } catch (error) {
        console.error('Error fetching last updated datetime:', error);
      }
    };

    fetchPredictions();
    fetchLastUpdated();
  }, []);

  const calculateStatistics = (predictionsArray) => {
    const matchStats = [...Array(51)].map(() => ({}));
    const bonusStats = [...Array(10)].map(() => ({}));
    const bonusAverages = [...Array(5)].map(() => 0);

    predictionsArray.forEach(prediction => {
      [...Array(51)].forEach((_, index) => {
        const key = `p_match${index + 1}`;
        matchStats[index][prediction[key]] = (matchStats[index][prediction[key]] || 0) + 1;
      });
      [...Array(5)].forEach((_, index) => {
        const key = `p_bonusvraag${index + 1}`;
        bonusStats[index][prediction[key]] = (bonusStats[index][prediction[key]] || 0) + 1;
      });
      [...Array(5)].forEach((_, index) => {
        const key = `p_bonusvraag${index + 6}`;
        bonusAverages[index] += Number(prediction[key]);
      });
    });

    // Convert counts to percentages for matchStats and bonusStats
    const totalPredictions = predictionsArray.length;
    const matchStatsPercentages = matchStats.map(stats => {
      const percentages: Record<string, string> = {};
      Object.keys(stats).forEach(key => {
        percentages[key] = ((stats[key] / totalPredictions) * 100).toFixed(1) + '%';
      });
      return percentages;
    });
    const bonusStatsPercentages = bonusStats.map(stats => {
      const percentages: Record<string, string> = {};
      Object.keys(stats).forEach(key => {
        percentages[key] = ((stats[key] / totalPredictions) * 100).toFixed(1) + '%';
      });
      return percentages;
    });

    // Calculate averages for the last five bonus questions
    const bonusAveragesValues = bonusAverages.map(avg => (avg / totalPredictions).toFixed(0));

    return { matchStatsPercentages, bonusStatsPercentages, bonusAveragesValues };
  };

  // Filter only paid predictions for statistics
  const paidPredictions = predictions.filter(prediction => prediction.betaald);
  const unpaidPredictions = predictions.filter(prediction => !prediction.betaald);

  const { matchStatsPercentages, bonusStatsPercentages, bonusAveragesValues } = calculateStatistics(paidPredictions);

  const renderPredictionsTable = (predictionsArray) => (
    <table className='voorspellingen-table'>
      <thead>
        <tr>
          <th className='voorspellingen-th'>Gebruiker</th>
          <th className='voorspellingen-th'>Winnaar</th>
          {[...Array(51)].map((_, index) => (
            <th key={`match${index + 1}`} className='voorspellingen-th'>{mapping[`match${index + 1}`]}</th>
          ))}
          {[...Array(10)].map((_, index) => (
            <th key={`bonusvraag${index + 1}`} className='voorspellingen-th'>{mapping[`bonusvraag${index + 1}`]}</th>
          ))}
          <th className='voorspellingen-th'>Betaald</th>
        </tr>
      </thead>
      <tbody>
        {predictionsArray.map((userPrediction, index) => (
          <tr key={index}>
            <td className='voorspellingen-td'>{userPrediction.gebruiker}</td>
            <td className='voorspellingen-td'>
              <div className='flag'>
                <img
                  src={flagMapping[userPrediction.p_winnaar] || ""}
                  width="20"
                  alt={userPrediction.winnaar}></img>
              </div>
            </td>
            {[...Array(51)].map((_, index) => (
              <td key={`match${index + 1}`} className='voorspellingen-td'>{userPrediction[`p_match${index + 1}`]}</td>
            ))}
            {[...Array(10)].map((_, index) => (
              <td key={`bonusvraag${index + 1}`} className='voorspellingen-td'>{userPrediction[`p_bonusvraag${index + 1}`]}</td>
            ))}
            <td className='voorspellingen-td' style={{ color: userPrediction.betaald === true ? 'green' : 'red' }}>
              {userPrediction.betaald === true ? 'Ja' : 'Nee'}
            </td>
          </tr>
        ))}
        <tr>
          <td className='voorspellingen-td'>Statistieken</td>
          <td className='voorspellingen-td'></td>
          {[...Array(51)].map((_, index) => (
            <td key={`match-stats-${index + 1}`} className='voorspellingen-td'>
              {Object.entries(matchStatsPercentages[index]).map(([value, percentage]) => (
                <div key={value}>{value}: {percentage}</div>
              ))}
            </td>
          ))}
          {[...Array(10)].map((_, index) => (
            <td key={`bonus-stats-${index + 1}`} className='voorspellingen-td'>
              {Object.entries(bonusStatsPercentages[index]).map(([value, percentage]) => (
                <div key={value}>{value}: {percentage}</div>
              ))}
            </td>
          ))}
          {/* {[...Array(5)].map((_, index) => (
            <td key={`bonus-avg-${index + 6}`} className='voorspellingen-td'>
              Gemiddelde: {bonusAveragesValues[index]}
            </td>
          ))} */}
          <td className='voorspellingen-td'></td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div className='voorspellingen-centered'>
      <h3>
        U heeft {userStatus.betaald ? (
          'al betaald en speelt mee!'
        ) : (
          <>
            nog niet betaald.{' '}
            <a href="https://betaalverzoek.rabobank.nl/betaalverzoek/?id=xbV8wGMnQVqkcQ9oLRdusQ" target="_blank" rel="noopener noreferrer">
              Betalen kan hier.
            </a>
          </>
        )}
      </h3>
      <h1>Voorspellingen</h1>
      {lastUpdated && (
        <p className='last-updated'>Laatste update: {lastUpdated}</p>
      )}
      <div className='voorspellingen-predictions-table-container'>
        <h2>Gebruikersvoorspellingen</h2>
        <div className='voorspellingen-table-scroll'>
          {renderPredictionsTable(paidPredictions)}
          {userStatus.is_admin &&
          <div>
            <h3>Onbetaalde Gebruikers</h3>
            {renderPredictionsTable(unpaidPredictions)}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default VoorspellingenPage;
