// src/Nieuws.tsx

import React from 'react';
import './nieuws.css'; // Import your CSS file

const Nieuws: React.FC = () => {
  return (
    <div className="nieuws-page">
      <h1 className='center'>Nieuws</h1>

      <div className="story">
        <h2>Update Kwartfinales</h2>
        <p>Goedemiddag supervoorspellers 🧠,</p>

        <p>Niet alleen Donyell Malen, maar ook de tijd vliegt voorbij. We zijn namelijk alweer bij de kwartfinales, wat betekent dat er nog maar 7 wedstrijden overblijven in dit fantastische eindtoernooi. Gelukkig kunnen wij nog genieten van een aantal enorme krakers.</p>

        <p>Vanavond om 18:00 uur staat de titanenstrijd tussen Duitsland en Spanje op het programma. Deze twee grootste kanshebbers op de titel moeten helaas al in de kwartfinales tegen elkaar spelen. Voor de uitslagen in de poule is dit natuurlijk enorm doorslaggevend. Voor velen van jullie zal deze wedstrijd erop of eronder zijn. Daarbij komt ook dat deze wedstrijd waarschijnlijk zal bepalen welk team de meeste doelpunten voor zal hebben.</p>

        <p>Verder hebben we om 21:00 uur de wedstrijd tussen Portugal en Frankrijk. Hoewel Frankrijk al lang niet meer de favoriet is bij de bookmakers, weten zij toch altijd goed te presteren op toernooien. Dus wie weet wat zij vanavond kunnen brengen. Deze wedstrijd tussen de teams van Mistiano Penaldo en Kylian Mbappé zal hopelijk minder teleurstellen dan hun prestaties op het EK tot nu toe.</p>

        <p>Naast dat Engeland morgen het zal opnemen tegen Zwitserland, zijn in Nederland uiteraard alle ogen gericht op de wedstrijd van 21:00 uur tegen Turkije. Voor ons Oranje zit het dit toernooi tot nu toe erg mee; van de laatste 8 teams hadden we geen betere tegenstander kunnen treffen dan Turkije. Zal Oranje wederom een goede wedstrijd spelen of zullen we de Turken de hele nacht door horen tuuuteren? 📢🎵</p>

        <p>Terugkomend op de poule zal ik u even kort een update geven over de tussenstand! Nu de waardes van de bonusvragen zijn verwerkt en Georgië de meeste tegendoelpunten heeft gekregen, is er nog het een en ander veranderd. De huidige top 5 bestaat uit Richard Laan, Steven Savelberg, Dennis Botman, Georg Schrumpf en Jaap Bakker aan kop. 👑 Onderaan is het een strijd tussen Peppa Pig, Hans van Baar en Michel Buurman over wie het vuilnis mag wegbrengen. Felicitaties aan Coby Vestappen, die eindelijk de laatste plek heeft verlaten!</p>
        
        <p>Veel succes en plezier allemaal nog met de laatste wedstrijden!</p>

        <p>Groeten van Thijs 🥅</p> 
      </div>


      <div className="story">
        <h2>Dat was de eerste speeldag</h2>
        <p>Goedemorgen puntenpakkers ⚽,</p>

        <p>De eerste speeldag zit er inmiddels alweer op. We hebben alle teams van dit eindtoernooi hun eerste pogingen tot excellentie mogen zien doen. De teams die naar onze mening hierin het meeste uitblonken waren Duitsland (dat gemakkelijk 5-1 wist te winnen van Schotland), Spanje (dat tegen de lastige tegenstander Kroatië toch met een ruime zege wist te winnen) en Roemenië, dat ondanks een misschien wel sterker Oekraïne wist te winnen. Ook zijn wij benieuwd of het tegenvallende Italië nog beter gaat worden en of België überhaupt de volgende fase gaat halen.</p>

        <p>In Poule D van Nederland wisten wij gelukkig met de legendarische finisher <span className="highlight">Wout Weghorst</span> nog de winst te pakken tegen Polen. Hoewel deze wedstrijd spannender was dan hij moest zijn, begint de oranjekoorts nu steeds hogere temperaturen te bereiken. 🧡 Ook Frankrijk had een lastige wedstrijd tegen een sterk Oostenrijk, maar wist toch met 1-0 te winnen. Aankomende vrijdag mogen wij het om 21:00 opnemen tegen angstgegner Frankrijk. Zonder de geblesseerde <span className="highlight">Mbappé</span> zouden we toch een kans moeten maken.</p>

        <p>Verder hebben we in de <span className="highlight">pouledesroods</span> al een hele tijd een koploper. <span className="highlight">Fedde Weenink</span> heeft met zijn stapel aan bonuspunten al lange tijd grip op deze eerste plaats. Echter zal hij deze bonuspunten ook snel weer verliezen, want het is niet aannemelijk dat hij met zijn 31 doelpunten en 22 gele kaarten deze bonuspunten lang zal behouden. In de belangrijkere toto doen <span className="highlight">Tim Zuidhof</span> en <span className="highlight">Pedro</span> het momenteel het beste met 55 punten. Zij hebben hiermee 11 van de 12 wedstrijden tot nu toe goed voorspeld en bereikten beide een streak van 8. 🔥 Gefeliciteerd heren!</p>

        <p>Onderaan bungelt <span className="highlight">Geert Jan de Groot</span> met momenteel 10 punten. Wij denken wel dat deze dieselmotor binnenkort gaat opstomen, dus wie weet zien we hem over een week wel bovenaan.</p>

        <p>Vandaag gaan we verder met de volgende speelronde en hebben wij weer drie wedstrijden op het programma:
          <ul>
            <li><span className="highlight">15:00 Kroatië - Albanië</span></li>
            <li><span className="highlight">18:00 Duitsland - Hongarije</span></li>
            <li><span className="highlight">21:00 Schotland - Zwitserland</span></li>
          </ul>
        </p>

        <p>Wij wensen jullie alvast veel kijkplezier.</p>

        <p>Groeten van de Poule des Roods 2024 🥅</p>

        <p>PS: Ik heb een aantal voorbeelden toegevoegd aan het reglement om de puntentelling voor de finalerondes uit te leggen. Mochten hier nog vragen over zijn, mail mij dan gerust terug!</p>
      </div>

      <div className="story">
        <h2>Eerste mail</h2>
        <p>Goedemiddag deelnemers ⚽,</p>
        <p>Gisteren is het Europees kampioenschap 2024 🏆 van start gegaan met de openingswedstrijd Duitsland – Schotland. Voor degenen die dit niet hebben gezien: het is geëindigd in een overtuigende winst van het thuisland, dat met 5-1 wist te winnen. Naast meer dan een handvol eerste doelpunten is er ook al een eerste rode kaart in deze wedstrijd gevallen.</p>
        <p>De eerste tussenstand hebben wij daarom gisteravond geüpload en hierin is <span className="highlight">Steve Keya</span> onze eerste koploper met 15 punten. Dit zegt natuurlijk nog niets, want het toernooi is pas net begonnen. Maar een goed begin, Steve! Hopelijk weten hekkensluiters <span className="highlight">Dery Oosterhof</span> en <span className="highlight">Agnes Leenen</span> snel wat punten te pakken. Zij waren, samen met <span className="highlight">Marco van den Hof</span> en <span className="highlight">Erik Rood</span>, de enigen die Duitsland niet op winst hadden gezet en wisten ook geen bonuspunten te pakken. Ik zal het er niet verder in wrijven.</p>

        <p>Verder hebben wij aan de hand van jullie voorspellingen een aantal leuke statistieken verzameld. Frankrijk is met <span className="important">34,85%</span> verreweg het meest gekozen als aankomend Europees kampioen. Daarna volgen Engeland (<span className="important">18,18%</span>), Nederland (<span className="important">17,42%</span>), Duitsland (<span className="important">10,61%</span>), Spanje (<span className="important">9,09%</span>) en maar liefst <span className="important">9,85%</span> van de mensen koos voor een ander land.</p>

        <p>Vandaag staan de wedstrijden <span className="highlight">Hongarije – Zwitserland</span> (nu), <span className="highlight">Spanje – Kroatië</span> (18:00) en <span className="highlight">Italië – Albanië</span> (21:00) op het programma. Vanavond volgt er weer een update. Veel plezier!</p>

        <p>Groeten van de Poule des Roods 2024 🥅</p>
        <p>PS: Mensen kunnen nu al bonuspunten hebben omdat iemand bijvoorbeeld de huidige topscorer goed heeft, of dichtbij het huidige aantal rode of gele kaarten heeft gegokt. Spring niet te snel van stapel want dit kan ook weer minder worden!</p>
      </div>
      <div className="story">
        <h2>We zijn van start!</h2>
        <p>De inschrijvingen en wijzigingen zijn nu per definitief gesloten. Er is een mooie update gedaan in het klassement en jullie kunnen nu elkaars voorspellingen bekijken. Snel zullen er ook statistieken worden weergeven! Morgen is al de eerste wedstrijd om 21:00 neemt gastland Duitsland het namelijk op tegen schotland. Veel plezier.</p>
      </div>
      <div className="story">
        <h2>Selectie Nederlands Elftal</h2>
        <img src="/nederlandselftal.png" alt="Foto van het nederlands elftal" className='nederlandselftal' />
        <p>De selectie voor het Nederlands elftal is nu eindelijk bekend. Hoewel een aantal belangrijke namen niet met ons mee afreizen naar Duitsland is het toch leuk om te zien dat we best een aantal goede spelers hebben. Tot nu toe heeft nog bijna niemand ingezet op de winst van ons oranje dus misschien is het juist een buitenkansje.</p>
      </div>
      <div className="story">
        <h2>Updates Poule</h2>
        <p>Inmiddels doen rond de 50 mensen mee met de poule en hebben we nog 12 dagen te gaan. Wij verwachten dat er nog wel een aantal mensen bij zullen komen, wat natuurlijk erg leuk is. Achter de schermen worden hier en daar nog een paar updates doorgevoert om het makkelijker te maken om de poule in te vullen en scores aan te passen of te bewerken. Mocht je nog een toevoeging hebben voor deze of een volgende editie mag je ons altijd even mailen.</p>
      </div>
      <div className="story">
        <h2>Mailtjes over de Poule</h2>
        <p>De eerste deelnemers zijn al binnen en we zitten nu al op een mooie 20 aanmeldingen. Ik ben benieuwd wat dit seizoen allemaal gaat brengen. Voor de fans van afgelopen jaren, wij gaan kijken of wij naast deze nieuwsberichten ook nog mooie mailtjes naar jullie gaan versturen met tussenstanden. Verder zijn andere toevoegingen en tips altijd welkom.</p>
      </div>
      <div className="story">
        <h2>Start Nieuwe Poule</h2>
        <p>De poule is hierbij officieel gelanceerd. Dit is de eerste editie dat wij het niet via heel mooie Excel sheets doen maar via een websiteje. We hopen dat dit ook bevalt! Uiteraard is feedback altijd welkom. En uiteraard... veel succes allemaal.</p>
      </div>
      {/* Add more stories as needed */}
    </div>
  );
};

export default Nieuws;
