import React from 'react';
import './regelement.css';

const App: React.FC = () => {
  return (
    <div className="app">

      <h1 className='centertext'>Toernooiregels</h1>
      <div className='centertext'>
        <p>
          <strong>Voorspellingen: </strong> Voor het voorspellen van de TOTO kiest men het land dat men denkt dat zal winnnen, of mogelijk een gelijkspel.
          In de finale wordt de uitslag aangehouden die bereikt is na het verstrijken van de reguliere speeltijd
          inclusief de blessuretijd. Een gelijkspel in de finale is dus ook mogelijk.
        </p>
        {/* <p>
          <strong>Deadline: </strong>Het inschrijfformulier moet voor <strong>11 juni, 23:59 (1 dag verlengd)</strong>, ingevuld zijn. Daarna zal de inschrijfpagina sluiten en kunnen er geen wijzigingen meer worden gemaakt aan uw voorspellingen.
        </p> */}
        {/* <p>
          <strong>Inschrijfgeld:</strong> Ook voor 11 juni, 23:59, moet de verplichte inleg van €10,00 zijn overgemaakt zodat wij uw account op actief kunnen zetten. Het geld kan worden overgemaakt naar:
        </p> */}
        {/* <ul>

          <ul>
            <li>Rekeningnummer: NL50RABO0117630861</li>
            <li>Ter name van: T. Rood</li>
          </ul>
          <li>Onder vermelding van: uw naam en 'EK2024'</li>
          <li>Betalen kan ook met het volgende linkje:&nbsp;   
            <a href="https://betaalverzoek.rabobank.nl/betaalverzoek/?id=xbV8wGMnQVqkcQ9oLRdusQ " target="_blank" rel="noopener noreferrer">
              klik hier om te betalen.
            </a>
          </li>
        </ul> */}
        {/* <br></br> */}
        <p><strong>In gevallen waar het reglement niet in voorziet, beslist de wedstrijdleiding.</strong></p>
        <p>Door het inleveren van het deelname formulier gaat u akkoord met dit wedstrijdreglement.</p>
        <br></br>
      </div>

      <div className="points-info centertext">
        <h1 className='centertext'>Prijzenpot</h1>
        <p>
          De verdeling van het prijzengeld gebeurt als volgt:
        </p>
        <ol>
          <li><strong>Nr. 1: 35,0% van de prijzenpot</strong></li>
          <li>Nr. 2: 25,0% van de prijzenpot</li>
          <li>Nr. 3: 20,0% van de prijzenpot</li>
          <li>Nr. 4: 10,0% van de prijzenpot</li>
          <li>Nr. 5: 5,0% van de prijzenpot</li>
        </ol>
        <p>
          Indien meerdere spelers op dezelfde positie staan en recht hebben op prijzengeld, zal de totale som van het prijzengeld voor de gedeelde plekken verdeeld worden onder de rechthebbenden.
        </p>
        <p>
          5% van het speelgeld zal gebruikt worden voor het draaien van de poule.
        </p>
        <br></br>
      </div>



      <div className="points-info centertext">
        <h1 className='centertext'>Puntentelling</h1>

        <ul>
          <li><strong>De puntentelling van de poule gaat als volgt:</strong></li>
          <li>25 Punten voor het goed voorspellen van de winnaar van het EK.</li>
          <li>20 Punten voor het goed voorspellen van de uitslag van de Finale</li>
          <li>20 Punten voor het goed voorspellen van de uitslag van de Halve Finales (finalist)</li>
          <li>15 Punten voor het goed voorspellen van de uitslag van de Kwart Finales (halve finalist)</li>
          <li>10 Punten voor het goed voorspellen van de uitslag van de Achtste Finale (kwartfinalist)</li>
          <li>5 Punten voor het goed voorspellen van de uitslag van de wedstrijden in de poulefase</li>
          <br></br>

          <li>Voor een team dat in een finaleronde op de verkeerde plaats wordt voorpselt worden 50% van de punten uitgereikt</li>
          <li>Extra voorbeelden toegevoegd:</li>
          <li><i>Voorbeeld 1: u voorspelt als finalist 1 Spanje, en als finalist 2 Italië. Echter is de finale Italië - Spanje waardoor finalist 1 Italië is en finalist 2 Spanje is. U krijgt dan 50% van (20 + 20) = 50% van 40 punten = 20 punten</i></li>
          <li><i>Voorbeeld 2: u voorspelt als kwartfinalist 3 Albanië. Echter is Albanië 1e in groep 1 geworden en daardoor kwartfinalist 5. U krijgt dan hiervoor 50% van het voorspellen van een kwartfinalist dit is 50% van 10 punten = 5 punten </i></li>

          <li><strong>Voor de bonusvragen geldt de volgende puntentelling</strong></li>
          <li>Voor bonusvraag 1 t/m 5 kan men 10 punten behalen</li>
          <li>Voor bonusvraag 6 t/m 10: krijgt men 10 punten met 2 punten aftrek voor elk getal ernaast (inschattingsvragen)</li>
          <li><i>Verdere verduidelijking:</i></li>
          <li>Het aantal rode en gele kaarten die meetellen zijn alleen voor de spelers, dus niet voor de coaches en andere stafff</li>
          <li>De data voor de bonusvragen haal ik van de website van UEFA</li>
          <br></br>
        </ul>
      </div>


    </div>
  );
};

export default App;
