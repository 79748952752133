// src/pages/HomePage.tsx

import React from 'react';
import './homepage.css';
import ekImage from './images/ek-2024-poules.jpg';

const HomePage: React.FC = () => {
  return (
    <div className="home-page-container">
    <h1>Welkom bij de</h1>
    <h1>"Poule des Roods" 2024!</h1>
    <img src={ekImage} alt="EK 2024 Poules" className="ek-image" />
    <p>
      Ben jij klaar voor het Europees Kampioenschap Voetbal 2024? Doe mee met onze poule
      en voorspel de wedstrijduitslagen van het EK 2024!
    </p>

    <p>
      Maak jouw voorspellingen voor elke wedstrijd, scoor punten en strijd tegen andere
      deelnemers om de toppositie op het leaderboard te bereiken. Of je nu een voetbalkenner
      bent of gewoon geluk hebt, iedereen is welkom om deel te nemen aan deze spannende
      voetbalervaring.
    </p>

    <h2>Hoe het werkt</h2>
    <div className='center'>
      <p>Log in of registreer om toegang te krijgen tot de poule.</p>
      <p>Bekijk het speelschema van het EK 2024.</p>
      <p>Voorspel de uitslagen van de wedstrijden en beantwoord bonusvragen.</p>
      <p>Verdien punten op basis van de nauwkeurigheid van je voorspellingen.</p>
      <p>Bekijk je positie op het leaderboard en daag vrienden uit voor een competitie.</p>
      <p>Doe mee en maak het Europees Kampioenschap Voetbal 2024 nog spannender!</p>
      <p>Veel succes en plezier!</p>
    </div>
  </div>
  );
};

export default HomePage;
