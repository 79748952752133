// api.js (or any other appropriate file)
import config from './config';
import { getJwtToken } from './auth';

const reactServer = config.reactServer;

const checkUserStatus = async () => {
  const token = getJwtToken();

  try {
    const response = await fetch(reactServer + 'check_login', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    // credentials: 'include',  // Include credentials (cookies) in the request
    });

    if (response.ok) {
      console.log('Retrieved login status from the server')
      return await response.json();
    } else {
      console.error('Failed to check login status');
      console.log('success')
      return { logged_in: false, is_admin: false, betaald: false, naam: '', email: ''};
    }
  } catch (error) {
    console.error('Error while checking login status:', error);
    console.log('error')
    return { logged_in: false, is_admin: false, betaald: false, naam: '', email: ''};
  }
};

export default checkUserStatus;
