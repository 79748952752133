import React, { useState, useEffect } from 'react';
import './tussenstand.css'; // Import CSS file
import config from '../config';
import mapping from "./mapping.json";
import checkUserStatus from '../checkuserstatus';

const TussenstandPage: React.FC = () => {
  const reactServer = config.reactServer;
  const [standings, setStandings] = useState([]);
  const [userPredictions, setUserPredictions] = useState({});
  const [results, setResults] = useState({});
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);

  // login handling
  const [userStatus, setUserStatus] = useState({
    logged_in: false,
    is_admin: false,
    betaald: false,
    naam: '',
    email: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      const status = await checkUserStatus();
      setUserStatus(status);
      // Fetch user predictions only if status has email
      if (status.email) {
        fetchUserPredictions(status.email);
      }
    };
    fetchData();

    const fetchStandings = async () => {
      try {
        const response = await fetch(reactServer + 'get_user_standings');
        if (response.ok) {
          const data = await response.json();
          setStandings(data);
        } else {
          console.error('Failed to fetch standings');
        }
      } catch (error) {
        console.error('Error fetching standings:', error);
      }
    };

    const fetchUserPredictions = async (email) => {
      try {
        const response = await fetch(`${reactServer}get_user_predictions_points?email=${email}`);
        if (response.ok) {
          const data = await response.json();
          setUserPredictions(data);
          console.log(data);
        } else {
          console.error('Failed to fetch user predictions');
        }
      } catch (error) {
        console.error('Error fetching user predictions:', error);
      }
    };

    const fetchResults = async () => {
      try {
        const response = await fetch(reactServer + 'get_results');
        if (response.ok) {
          const data = await response.json();
          setResults(data);
        } else {
          console.error('Failed to fetch results');
        }
      } catch (error) {
        console.error('Error fetching results:', error);
      }
    };

    const fetchLastUpdated = async () => {
      try {
        const response = await fetch(reactServer + 'get_last_updated');
        if (response.ok) {
          const data = await response.json();
          setLastUpdated(data.last_updated);
        } else {
          console.error('Failed to fetch last updated datetime');
        }
      } catch (error) {
        console.error('Error fetching last updated datetime:', error);
      }
    };

    fetchStandings();
    fetchResults();
    fetchLastUpdated();
  }, []);

  return (
    <div className='centered'>
      <h1>Mijn voorspellingen</h1>
      {lastUpdated && (
        <div>
          <p className='last-updated'>Laatste update: {lastUpdated} + 2 uur</p>
          <h3>
            U heeft {userStatus.betaald ? (
              'al betaald en speelt mee!'
            ) : (
              <>
                nog niet betaald.{' '}
                <a href="https://betaalverzoek.rabobank.nl/betaalverzoek/?id=xbV8wGMnQVqkcQ9oLRdusQ" target="_blank" rel="noopener noreferrer">
                  Betalen kan hier.
                </a>
              </>
            )}
          </h3>
        </div>
      )}

      {/* User predictions section */}
      <div className='user-predictions-table-container'>
        <table className='user-predictions-table'>
          <thead>
            <tr>
              <th>Wedstrijd</th>
              <th>Voorspelling</th>
              <th>Resultaat</th>
              <th>Punten</th>
            </tr>
          </thead>
          <tbody>
            {[...Object.keys(userPredictions)]
              .filter(key => !key.startsWith('p_')) // Exclude keys starting with 'p_'
              .sort((a, b) => {
                const matchPattern = /match(\d+)/;
                const bonusPattern = /bonusvraag(\d+)/;
                const matchA = matchPattern.exec(a);
                const matchB = matchPattern.exec(b);
                const bonusA = bonusPattern.exec(a);
                const bonusB = bonusPattern.exec(b);

                if (matchA && matchB) {
                  return parseInt(matchA[1]) - parseInt(matchB[1]);
                } else if (bonusA && bonusB) {
                  return parseInt(bonusA[1]) - parseInt(bonusB[1]);
                }
                return 0;
              })
              .map((key, index) => (
                <tr key={index}>
                  <td className='cell'>{mapping[key]}</td>
                  <td className='cell'>{userPredictions[key]}</td>
                  <td className='cell'>{results[key] || 'Nog niet bekend'}</td>
                  <td className='cell'>{userPredictions[`p_${key}`]}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TussenstandPage;
