import React, { useState } from 'react';
import './Registration.css'
import { useNavigate, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// Access the server variable
import config from '../config';


const LoginForm: React.FC = () => {
  const [email, setemail] = useState('');
  const [wachtwoord, setWachtwoord] = useState('');
  const [error, setError] = useState('');
  const [succes, setSucces] = useState('');
  const navigate = useNavigate();


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate the email and password
    const formData = {
      email: email,
      password: wachtwoord,
    };

    // Iterate over the form fields and check if each field is empty
    for (const [key, value] of Object.entries(formData)) {
      if (value === '') {
        // Display an error message to the user and prevent the form from being submitted
        setError('Alle velden dienen ingevuld te worden');
        return;
      }
    }

    // hier login
    const reactServer = config.reactServer;
    const response = await fetch(reactServer + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      const data = await response.text();
      setError(data);
    } else {
      const data = await response.json();
      console.log(data.message)
      // hier checkt ie of de login oke is
      if (data.message === 'User logged in 👍🏽') {
        // Redirect the user to the login page
        const token = data.access_token; // Adjust this based on your backend response
        // Store the token in localStorage
        localStorage.setItem('jwtToken', token);
        localStorage.setItem("email", email);
        setSucces("U bent juist ingelogd.")

        // Wait for 3 seconds before redirecting
        setTimeout(() => {
          window.location.href = "/"; // Redirect to the home screen
        }, 2000);
      } else {
        // Show an error message to the user
        setError(data.message);
      }
    }



  };

  return (
    <div className='login-container'>
      <h1 className="page-title">Login</h1>
      <form className="registration-form" onSubmit={handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="e-mail adres"
          value={email}
          onChange={(event) => setemail(event.target.value)}
        />
        <input
          type="password"
          name="wachtwoord"
          placeholder="Wachtwoord"
          value={wachtwoord}
          onChange={(event) => setWachtwoord(event.target.value)}
        />
        <button type="submit">Login</button>
        <p>Wachtwoorden zijn versleuteld.</p>
        <p>Let op: e-mail is hoofdletter gevoelig.</p>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {succes && <p style={{ color: 'black' }}>{succes}</p>}
      </form>
      {/* <div className='same-line login-pagina-tekst register-here'>
        Heb je nog geen account? <Link to="/register">Registreren.</Link>
      </div> */}
    </div>
  );
};

export default LoginForm;
