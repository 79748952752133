// src/App.tsx
import React, { useState, useEffect }  from 'react';
import {HashRouter as Router,Routes,Route,Link} from 'react-router-dom';

// import LogoAnimation from './logoAnimation';
import HomePage from './homepage';
import InschrijfPage from './pages/inschrijfformulier';
import RegelementPage from './pages/regelement';
import TussenstandPage from './pages/tussenstand';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import UitslagenPage from './pages/uitslagen';
import ProfielenPage from './pages/profielen';
import Voorspellingen from './pages/voorspellingen';
import MijnVoorspellingen from './pages/mijnvoorspellingen'
import Nieuws from './pages/nieuws'

import checkUserStatus from './checkuserstatus'
import LogoAnimation from './pages/logoAnimation';
import './App.css'; // Import your main App stylesheet here

import { getJwtToken, logout } from './auth';

// Access the server variable
import config from './config';
const reactServer = config.reactServer;


const App: React.FC = () => {

  // Navbar scrolling
  const [scrolled, setScrolled] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(true);
  // const [email, setEmail] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  // login handling
  const [userStatus, setUserStatus] = useState({
    logged_in: false,
    is_admin: false,
    betaald: false,
    naam: '',
    email: ''
  });

  useEffect(() => {
    const token = getJwtToken();

    const fetchData = async () => {
      const status = await checkUserStatus();
      setUserStatus(status);
      console.log('hierrrr')
      console.log(status)
    };
    fetchData();
  }, []);


  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleAnimationEnd = () => {
    setAnimationFinished(true);
    // setEmail(localStorage.getItem('email'))
    console.log("Animation ended")
  };

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // setEmail(localStorage.getItem('email'))
  }, []);


  const handleLogout = async () => {
    try {
      const response = await fetch(reactServer + 'logout', {
        method: 'POST',
        credentials: 'include',  // Include credentials (cookies) in the request
      });

      if (response.ok) {
        // Clear user data from local storage
        localStorage.removeItem('email');
        logout();


        // Update userStatus state
        setUserStatus({
          logged_in: false,
          is_admin: false,
          betaald: false,
          naam: '',
          email: ''
        });

        // Refresh the page
        window.location.reload();
      } else {
        console.error('Failed to logout');
      }
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };



  return (
    <div>
      {/* {animationFinished === false && (<LogoAnimation onAnimationEnd={handleAnimationEnd} />)} */}
      {animationFinished && (
      <Router>
        <div>
          <nav className={`navigation ${scrolled ? 'scrolled' : ''}`}>
            {userStatus.is_admin && <Link className="nav-link show-on-mobile hide-mobile" to="/uitslagen">Uitslagen Invoeren</Link>}
            {<Link className='nav-link hide-mobile' to='/nieuws'>Nieuws</Link>}
            <Link className="nav-link hide-mobile" to="/regelement">Regelement</Link>
            <Link className="nav-link show-on-mobile" to={userStatus.logged_in ? "/tussenstand" : "/login"}>Tussenstand</Link>
            {/* <Link className="nav-link show-on-mobile" to={userStatus.logged_in ? "/inschrijving" : "/login"}>Wijzig voorspelling</Link> */}
            <Link to="/">
              <img src='./logo.png' alt="Logo" className="nav-logo" />
            </Link>
            {/* <Link className="nav-link hide-mobile" to={userStatus.logged_in ? "/tussenstand" : "/login"}>Tussenstand</Link> */}
            <Link className="nav-link hide-mobile" to={userStatus.logged_in ? "/voorspellingen" : "/login"}>Voorspellingen</Link>
            {<Link className='nav-link' to={userStatus.logged_in ? "/mijnvoorspellingen" : "/login"}>Mijn Voorspellingen</Link>}
            {/* {email ? <Link className="nav-link show-on-mobile" to="/profile">{email}</Link> : <Link className="nav-link show-on-mobile" to="/login">Login</Link> }  */}
            <Link className="nav-link hide-mobile" to={userStatus.naam ? "/" : "/login"} onClick={handleLogout} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              {userStatus.naam ? (isHovered ? "Logout" : userStatus.naam) : "Login"}
            </Link>
            {userStatus.is_admin && <Link className="nav-link show-on-mobile" to="/profielen">Profielen</Link>}
            
            
 
          </nav>
        
          <div className='main-content'>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/regelement" element={<RegelementPage />} />
              {/* <Route path="/inschrijving" element={<InschrijfPage />} /> */}
              <Route path="/tussenstand" element={<TussenstandPage />} />
              <Route path="/login" element={<LoginPage />} />
              {/* <Route path="/register" element={<RegisterPage />} /> */}
              <Route path="/uitslagen" element={<UitslagenPage />} />
              <Route path="/profielen" element={<ProfielenPage />} />
              <Route path="/voorspellingen" element={<Voorspellingen />} />
              <Route path="/mijnvoorspellingen" element={<MijnVoorspellingen />} />
              <Route path="/nieuws" element={<Nieuws />} />
            </Routes>
          </div>
        </div>

        <footer className="footer">
          <div className="social-links">
            {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src="./linkedin-logo.png" alt="LinkedIn" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src="./instagram-logo.png" alt="Instagram" />
            </a> */}
          </div>
          <div className="copyright">
            &copy; {new Date().getFullYear()} "Poule des Roods" EK Poule 2024. All rights reserved.
          </div>
        </footer>
      </Router>)}
    </div>
  );
}

export default App;
