import React, { useState, useEffect } from 'react';
import './tussenstand.css'; // Import CSS file
import config from '../config';
import checkUserStatus from '../checkuserstatus';
import flameImage from '../images/fire.png';
import flagMapping from './flags.json';

const FlameWithStreak = ({ streak }) => (
  <div className='flame-container'>
    <img src={flameImage} alt='Streak Flame' className='flame-image' />
    <span className='streak-number'>{streak}</span>
  </div>
);

const TussenstandPage: React.FC = () => {
  const reactServer = config.reactServer;
  const [standings, setStandings] = useState([]);
  const [userPredictions, setUserPredictions] = useState({});
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [streaks, setStreaks] = useState({});
  const [movements, setMovements] = useState({});
  const [winners, setWinners] = useState({});

  // login handling
  const [userStatus, setUserStatus] = useState({
    logged_in: false,
    is_admin: false,
    betaald: false,
    naam: '',
    email: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch user status
        const status = await checkUserStatus();
        setUserStatus(status);

        // Fetch standings
        const standingsResponse = await fetch(reactServer + 'get_user_standings');
        if (standingsResponse.ok) {
          const standingsData = await standingsResponse.json();
          setStandings(standingsData);

          // Filter betaalde and niet-betaalde standings
          const betaaldeStandings = standingsData.filter(user => user.betaald);
          const nietBetaaldeStandings = standingsData.filter(user => !user.betaald);

          // Fetch winners for betaalde users
          const winnerMap = {};
          await Promise.all(
            betaaldeStandings.map(async (user) => {
              const winnerResponse = await fetch(`${reactServer}get_user_predictions_points?email=${user.email}`);
              if (winnerResponse.ok) {
                const winnerData = await winnerResponse.json();
                const winner = winnerData.winnaar || winnerData.p_winnaar || 'N/A';
                winnerMap[user.email] = winner;
              } else {
                console.error('Failed to fetch user predictions');
              }
            })
          );
          setWinners(winnerMap);
        } else {
          console.error('Failed to fetch standings');
        }

        // Fetch last updated datetime
        const lastUpdatedResponse = await fetch(reactServer + 'get_last_updated');
        if (lastUpdatedResponse.ok) {
          const lastUpdatedData = await lastUpdatedResponse.json();
          setLastUpdated(lastUpdatedData.last_updated);
        } else {
          console.error('Failed to fetch last updated datetime');
        }

        // Fetch streaks
        const streaksResponse = await fetch(reactServer + 'get_all_streaks');
        if (streaksResponse.ok) {
          const streaksData = await streaksResponse.json();
          const streakMap = streaksData.reduce((acc, item) => {
            acc[item.naam] = item.streak;
            return acc;
          }, {});
          setStreaks(streakMap);
        } else {
          console.error('Failed to fetch streaks');
        }

        // Fetch movements
        const movementsResponse = await fetch(reactServer + 'get_movement_data');
        if (movementsResponse.ok) {
          const movementsData = await movementsResponse.json();
          setMovements(movementsData);
        } else {
          console.error('Failed to fetch movements');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [reactServer]);

  const getRowClass = (index) => {
    switch (index) {
      case 0:
        return 'gold-row';
      case 1:
        return 'silver-row';
      case 2:
        return 'bronze-row';
      case 3:
      case 4:
        return 'last-row';
      default:
        return '';
    }
  };

  const renderMovement = (user) => {
    const movement = movements[user.email];
    if (movement === undefined) return null;
    if (movement === 0) return null;

    const arrow = movement > 10 ? '↑↑' : (movement > 0 ? '↑' : (movement < -10 ? '↓↓' : '↓'));
    const colorClass = movement > 0 ? 'positive-movement' : 'negative-movement';

    return (
      <span className={colorClass}>{arrow}{Math.abs(movement)}</span>
    );
  };

  
  const betaaldeStandings = standings.filter(user => user.betaald);
  const nietBetaaldeStandings = standings.filter(user => !user.betaald);

  return (
    <div className='centered'>
      <h1>Tussenstand</h1>
      {lastUpdated && (
        <p className='last-updated'>Laatste update: {lastUpdated}</p>
      )}

      {/* Standings section for betaald users */}
      <div className='standings-table-container'>
        <table className='standings-table'>
          <thead>
            <tr>
              <th>Positie</th>
              <th>Naam</th>
              <th>Winnaar</th>
              <th>Totale punten</th>
              <th>Toto punten</th>
              <th>Bonus punten</th>
            </tr>
          </thead>
          <tbody>
            {betaaldeStandings.map((user, index) => (
              <tr key={user.naam} className={getRowClass(index)}>
                <td>
                  <span style={{ marginRight: '1.5em' }}>{index + 1}</span>
                  {renderMovement(user)}
                </td>
                <td className='space'>
                  <div>{user.naam} </div>
                  {streaks[user.naam] ? <FlameWithStreak streak={streaks[user.naam]} /> : null}
                </td>
                <td>
                  <img
                    src={flagMapping[winners[user.email]] || ""}
                    width="20"
                    alt={winners[user.email]}></img>
                </td>
                <td>{user.total_points}</td>
                <td>{user.toto_points}</td>
                <td>{user.bonus_points}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Standings section for niet-betaald users */}
      {userStatus.is_admin &&
        <div className='standings-table-container'>
          <h2>Algemene Stand (Niet-Betaald)</h2>
          <p className='last-updated'>Zal binnenkort verdwijnen.</p>
          <table className='standings-table'>
            <thead>
              <tr>
                <th>Positie</th>
                <th>Naam</th>
                <th>Totale punten</th>
                <th>Toto punten</th>
                <th>Bonus punten</th>
              </tr>
            </thead>
            <tbody>
              {nietBetaaldeStandings.map((user, index) => (
                <tr key={user.naam}>
                  <td>{index + 1}</td>
                  <td>
                    <div>{user.naam} </div>
                    {streaks[user.naam] ? <FlameWithStreak streak={streaks[user.naam]} /> : null}
                  </td>
                  <td>{user.total_points}</td>
                  <td>{user.toto_points}</td>
                  <td>{user.bonus_points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>}
    </div>
  );
};

export default TussenstandPage;
