import React, { useState, useEffect } from 'react';
import config from '../config';
import { getJwtToken } from '../auth';
import checkUserStatus from '../checkuserstatus';

const ProfielenPage: React.FC = () => {
    const reactServer = config.reactServer;
    const [profiles, setProfiles] = useState([]);
    const [userStatus, setUserStatus] = useState({
        logged_in: false,
        is_admin: false,
        betaald: false,
        naam: '',
        email: ''
    });

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const response = await fetch(reactServer + 'get_user_profiles');
                if (response.ok) {
                    const data = await response.json();
                    setProfiles(data);
                } else {
                    console.error('Failed to fetch user profiles');
                }
            } catch (error) {
                console.error('Error fetching user profiles:', error);
            }
        };

        fetchProfiles();
    }, [reactServer]);

    useEffect(() => {
        const fetchData = async () => {
            const status = await checkUserStatus();
            setUserStatus(status);
        };

        fetchData();
    }, []);

    const handleCheckboxChange = async (id: number, checked: boolean) => {
        try {
            const token = getJwtToken();
            const response = await fetch(reactServer + '/update_betaald', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userId: id,
                    betaald: checked
                }),
            });

            if (!response.ok) {
                console.error('Failed to update betaald status');
            } else {
                console.log('Changes betaald status');
            }
        } catch (error) {
            console.error('Error updating betaald status:', error);
        }
    };

    const copyEmails = () => {
        const emails = profiles
            .filter(user => user.betaald)
            .map(user => user.email)
            .join(', ');
        
        navigator.clipboard.writeText(emails)
            .then(() => {
                console.log('Emails copied to clipboard');
            })
            .catch(err => {
                console.error('Failed to copy emails: ', err);
            });
    };

    return (
        <div className='centered'>
            {userStatus.is_admin && (
                <div>
                    <h1>Profielen</h1>
                    <b>Vergeet niet te refreshen na het aanklikken van een gebruiker</b>
                    <br />
                    <button onClick={copyEmails}>Kopieer email</button>
                    <table>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Naam</th>
                                <th>Email</th>
                                <th>Betaald</th>
                            </tr>
                        </thead>
                        <tbody>
                            {profiles.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.naam}</td>
                                    <td>{user.email}</td>
                                    <td>
                                        <input
                                            type="checkbox"
                                            checked={user.betaald}
                                            onChange={(e) => handleCheckboxChange(user.id, e.target.checked)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default ProfielenPage;
